.header {
    background: $white;
    height: $header-height;
    border-bottom: 1px solid $border-color;
    position: fixed;
    top: 0px;
    width: 100%;
    z-index: $zindex-navbar;
    display: flex;
    box-shadow: 15px 29px 22px -23px rgba(0,0,0,0.17);
    -webkit-box-shadow: 15px 29px 22px -23px rgba(0,0,0,0.17);
    -moz-box-shadow: 15px 29px 22px -23px rgba(0,0,0,0.17);
    .logo {
        width: $side-nav-width;
        border-right: 1px solid $border-color;
        @include transition(all 0.2s ease); 

        @include screen-laptop-only {
            width: $side-nav-width-laptop;
        }

        @include screen-tablet {
            display: none;
        }
        
        > a {
            display: block;
            text-align: center;
        }

        .logo-fold {
            display: none;
        }

        &.logo-white {
            display: none;
        }
    }

    .nav-wrap {
        flex: 1;
        display: flex;
        justify-content: space-between;



        .nav-left,
        .nav-right {
            list-style-type: none;
            padding-left: 0px;
            margin-bottom: 0px;
            align-items: center;
            display: flex;

            li {

                > a,
                nz-dropdown > a {
                    padding: 5px 15px;
                    font-size: 18px;
                    color: $body-color;
                    display: block;
                    @include transition(background-color 0.3s); 
                    border-radius: $border-radius;

                    &:hover,
                    &:focus,
                    &:active {
                        color: $brand-primary;
                        background-color: rgba($brand-primary, 0.1);

                        .ant-badge {
                            color: $brand-primary; 
                        }    
                    }

                    .ant-badge {
                        color: $body-color;
                        font-size: 18px;
                    }
                }
            }
        }

        .nav-left {
            margin-left: 10px;
        }

        .nav-right {
            margin-right: 10px;
        }

        .desktop-toggle {
            @include screen-tablet {
                display: none;
            }
        }

        .mobile-toggle {
            @include screen-tablet-above {
                display: none;
            }
        }
    }

    .ant-dropdown-link,
    .ant-dropdown-trigger  {
        .anticon:not(.anticon-ellipsis) {
            font-size: 18px;
            transform: none;
        }
    }

    &.header-folded {

        .logo {
            width: $side-nav-folded;
        }
    }
}

.pop-notification {
    width: 350px;
}

.is-folded {
    @include screen-tablet-above {
        .header {

            .logo {
                width: $side-nav-folded;

                img {
                    display: none;
                }

                .logo-fold {
                    display: block;
                }
            }
        }    
    }
}

.is-primary,
.is-secondary,
.is-success,
.is-danger,
.is-custom {

    .header {
        border-bottom: 0px;

        .logo {
            border-color: transparent;

            &.logo-dark {
                display: none;
            }

            &.logo-white {
                display: block;
            }
        }

        .nav-left,
        .nav-right {

            li {

                > a,
                nz-dropdown > a {
                    color: rgba($white, 0.8);

                    &:hover,
                    &:focus,
                    &:active {
                        color: $white;
                        background-color: rgba($white, 0.15);

                        .ant-badge {
                            color: $white; 
                        }    
                    }

                    .ant-badge {
                        color: rgba($white, 0.8);
                    }
                }    
            }
        }        
    }
}

@each $theme in $theme-color-loop {
    .#{nth($theme, 1)} {

        .header {
            background-color: #{nth($theme, 2)};
        }
    }
}