@import url('./assets/lib/hljs/styles/atom-one-light.css');
@import url('../node_modules/ng-zorro-antd/src/ng-zorro-antd.min.css');
@import url('../node_modules/@fortawesome/fontawesome-free/css/all.min.css');
@import url('../node_modules/ngx-spinner/animations/ball-scale-multiple.css');
@import './assets/scss/app.scss';
@import "../node_modules/angular-calendar/css/angular-calendar.css";

/* For WebKit browsers like Chrome, Safari, and Edge */
::-webkit-scrollbar {
  width: 6px; /* Set the width of the scrollbar */
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; /* Color of the track */
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #a8a8a8; /* Color of the handle */
  border-radius: 20px; /* Optional: round the corners of the handle */
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #aaaaaa; /* Darker color when hovering over the handle */
}

/* For Firefox */
* {
  scrollbar-width: thin; /* Makes the scrollbar thinner */
  scrollbar-color: #b6b6b6 #f1f1f1; /* Color of the handle and track */
}

.notify-error {
  background-color: #fff1f0;
}

.notify-success {
  background-color: #fff1f0;
}

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
}

.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}

.table tbody+tbody {
  border-top: 2px solid #dee2e6;
}

.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.table-bordered {
  border: 1px solid #dee2e6;
}

.table-bordered th,
.table-bordered td {
  border: 1px solid #dee2e6;
}

.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody+tbody {
  border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.table-hover tbody tr:hover {
  color: #212529;
  background-color: rgba(0, 0, 0, 0.075);
}

.table-primary,
.table-primary>th,
.table-primary>td {
  background-color: #b8daff;
}

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody+tbody {
  border-color: #7abaff;
}

.table-hover .table-primary:hover {
  background-color: #9fcdff;
}

.table-hover .table-primary:hover>td,
.table-hover .table-primary:hover>th {
  background-color: #9fcdff;
}

.table-secondary,
.table-secondary>th,
.table-secondary>td {
  background-color: #d6d8db;
}

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody+tbody {
  border-color: #b3b7bb;
}

.table-hover .table-secondary:hover {
  background-color: #c8cbcf;
}

.table-hover .table-secondary:hover>td,
.table-hover .table-secondary:hover>th {
  background-color: #c8cbcf;
}

.table-success,
.table-success>th,
.table-success>td {
  background-color: #c3e6cb;
}

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody+tbody {
  border-color: #8fd19e;
}

.table-hover .table-success:hover {
  background-color: #b1dfbb;
}

.table-hover .table-success:hover>td,
.table-hover .table-success:hover>th {
  background-color: #b1dfbb;
}

.table-info,
.table-info>th,
.table-info>td {
  background-color: #bee5eb;
}

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody+tbody {
  border-color: #86cfda;
}

.table-hover .table-info:hover {
  background-color: #abdde5;
}

.table-hover .table-info:hover>td,
.table-hover .table-info:hover>th {
  background-color: #abdde5;
}

.table-warning,
.table-warning>th,
.table-warning>td {
  background-color: #ffeeba;
}

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody+tbody {
  border-color: #ffdf7e;
}

.table-hover .table-warning:hover {
  background-color: #ffe8a1;
}

.table-hover .table-warning:hover>td,
.table-hover .table-warning:hover>th {
  background-color: #ffe8a1;
}

.table-danger,
.table-danger>th,
.table-danger>td {
  background-color: #f5c6cb;
}

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody+tbody {
  border-color: #ed969e;
}

.table-hover .table-danger:hover {
  background-color: #f1b0b7;
}

.table-hover .table-danger:hover>td,
.table-hover .table-danger:hover>th {
  background-color: #f1b0b7;
}

.table-light,
.table-light>th,
.table-light>td {
  background-color: #fdfdfe;
}

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody+tbody {
  border-color: #fbfcfc;
}

.table-hover .table-light:hover {
  background-color: #ececf6;
}

.table-hover .table-light:hover>td,
.table-hover .table-light:hover>th {
  background-color: #ececf6;
}

.table-dark,
.table-dark>th,
.table-dark>td {
  background-color: #c6c8ca;
}

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody+tbody {
  border-color: #95999c;
}

.table-hover .table-dark:hover {
  background-color: #b9bbbe;
}

.table-hover .table-dark:hover>td,
.table-hover .table-dark:hover>th {
  background-color: #b9bbbe;
}

.table-active,
.table-active>th,
.table-active>td {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover>td,
.table-hover .table-active:hover>th {
  background-color: rgba(0, 0, 0, 0.075);
}

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55;
}

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.table-dark {
  color: #fff;
  background-color: #343a40;
}

.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #454d55;
}

.table-dark.table-bordered {
  border: 0;
}

.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}

.table-dark.table-hover tbody tr:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  .table-responsive-sm>.table-bordered {
    border: 0;
  }
}

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  .table-responsive-md>.table-bordered {
    border: 0;
  }
}

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  .table-responsive-lg>.table-bordered {
    border: 0;
  }
}

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  .table-responsive-xl>.table-bordered {
    border: 0;
  }
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.table-responsive>.table-bordered {
  border: 0;
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.table-responsive>.table-bordered {
  border: 0;
}

.ant-input-affix-wrapper {
  border: 1px solid #a8aeb7c7;
}

.ant-checkbox-inner {
  border: 1px solid #a8aeb7c7;
}

.ant-input {
  border: 1px solid #a8aeb7c7;
}

.ant-radio-inner {

  border-color: #a8aeb7c7;

}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected a {
  color: #50b848;
}

.ant-menu-inline .ant-menu-item:after,
.ant-menu-vertical .ant-menu-item:after,
.ant-menu-vertical-left .ant-menu-item:after,
.ant-menu-vertical-right .ant-menu-item:after {
  border-right: 3px solid rgba(80, 184, 72, 0.89);
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  border: 1px solid #a8aeb7c7;
}

.ant-picker {
  border: 1px solid #a8aeb7c7;
}

label.label {
  margin-top: 8px;
}

.footer-class {
  border: 1px solid #c0c0c014;
  background: #c0c0c014;
  padding: 10px 8px;
}

[nz-button] {
  margin-right: 8px;
  margin-bottom: 12px;
}

.ant-form-item-has-error .ant-form-item-explain,
.ant-form-item-has-error .ant-form-item-split {
  margin-bottom: -18px;
}

.ant-form-item {
  margin-bottom: 20px;
}

.ant-form-item-explain,
.ant-form-item-extra {
  min-height: 19px;
}

.bold-500 {
  font-weight: bold;
}

nz-select {
  width: 100%;
}

.loading-container {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100%;
  width: 100%;
  z-index: 999999;
  background: #000000 !important;
  opacity: 0.5;
  text-align: center
}

.loading-image {
  position: fixed;
  left: 45%;
  margin-top: 10%;
  z-index: 999999;
  top: 0;
  bottom: 0;
  width: 160px;
  background: white;
  border-radius: 4px;
}

.map-canvas {
  width: 100%;
  height: 400px;

}


.angular-editor-textarea ul {
  padding: 10px 40px !important;
}

.ant-select-multiple .ant-select-selector {
  height: auto;
  min-height: 2.6625rem;
}

.ant-select-multiple .ant-select-selection-placeholder {
  left: 15px;
}

@media (min-width: 992px) {
  .dashboard-2 {
    flex: 0 0 19.99%;
    max-width: 19.99%;
  }

}

img.card-img-top {
  max-width: 100%;
  max-height: 100%;
  display: block;
  /* remove extra space below image */
}

[type="file"] {
  height: 0;
  overflow: hidden;
  width: 0;
}

[type="file"]+label {
  background: #5796f6;
  border: none;
  border-radius: 5px;
  color: #fff;
  width: 100%;
  text-align: center;
  cursor: pointer;
  display: inline-block;
  font-family: "Rubik", sans-serif;
  font-size: inherit;
  font-weight: 500;
  margin-bottom: 1rem;
  outline: none;
  padding: 10px;
  position: relative;

  .ant-radio-inner {
    width: 1.25rem;
    height: 1.25rem;
    border-width: 2px;
    border-style: solid;
    border-color: #9fa8b5;
    background-color: transparent;
  }

  transition: all 0.3s;
  vertical-align: middle;

  &:hover {
    background-color: darken(#5796f6, 10%);
  }


}


.padding-regist-20 {
  padding: 0px 20px;
}

marquee {
  background: white;
}

.rightTI {
  background: #ff002b;
  white-space: nowrap;
  overflow: hidden;
  animation: marquee 18s linear infinite;
}

.rightTI:hover {
  animation-play-state: paused;
}

@-webkit-keyframes marquee {
  0% {
    text-indent: 100%;
  }

  100% {
    text-indent: -100%;
  }
}

.rightCSS {
  background: #a35dc1;
  overflow: hidden;
}

.rightCSS div {
  position: relative;
  animation: CSSright linear 18s infinite;
}

@keyframes CSSright {
  0% {
    right: -100%;
  }

  100% {
    right: 100%;
  }
}

.rightCSS:hover div {
  animation-play-state: paused;
}

.rightJS {
  background: #ffa900;
}

.rightJQ {
  background: #00a753;
}

.marquee-css {
  float: left;
  width: 100%;
  padding: 15px 15px 30px 15px;
  margin: 22px 10px;
  height: 30px;
  color: rgba(80, 74, 74, 0.897);
  border-radius: 0.2em;
  box-shadow: 0 0.1em 0.5em;
}

.side-nav {
  
}

.img-zoom-lens {
  height: 125px !important;
  width: 125px !important;
}

.full-width {
  width: 100%;
}
.cal-day-number{
  opacity: 0.7 !important;
  color: #000000 !important;
  font-size: 1.28em !important;
}
.cal-month-view .cal-day-cell:not(:last-child) {
  border-right-color: #c5c5c5 !important;
}
.cal-month-view .cal-days .cal-cell-row {
  border-bottom-color: #c5c5c5;
}
.cal-day-cell.cal-weekend .cal-day-number {
 color: #000000 !important; 
}
.cal-day-cell.cal-out-month .cal-day-number {
  opacity: 0.2 !important;
}
.cal-day-cell {
  background-color: lightblue;
  /* Change to desired color */
}

.cal-day-cell:hover {
  background-color: lightcoral;
  /* Change to desired hover color */
}

.cal-day-cell.cal-today{
  background-color: transparent !important; /* Blue Grey */
  color: white !important; /* White text */
}


.cal-day-cell.cal-past {
  background-color: rgba(206, 206, 206, 0.51);
  /* Change to desired color for past dates */
}

.cal-day-cell.cal-future {
  background-color: #FFFFFF;
  /* Change to desired color for future dates */
}

.cal-day-cell .cal-day-selected {
  background-color: rgba(64, 158, 255, 0.6) !important;
  /* Change to desired selected date color */
}

.cal-month-view .bg-pink,
.cal-week-view .cal-day-columns .bg-pink,
.cal-day-view .bg-pink {
  background-color: hotpink !important;
}

.cal-month-view .bg-green,
.cal-week-view .cal-day-columns .bg-green,
.cal-day-view .bg-green {
  background-color: greenyellow !important;
}

.cal-month-view .bg-blue,
.cal-week-view .cal-day-columns .bg-blue,
.cal-day-view .bg-blue {
  background-color: rgba(64, 158, 255, 0.6) !important;
}


.cal-month-view .bg-halted,
.cal-week-view .cal-day-columns .bg-halted,
.cal-day-view .bg-halted {
  background-color: red !important;
  color: #fff !important;
}

.cal-month-view .bg-orange,
.cal-week-view .cal-day-columns .bg-orange,
.cal-day-view .bg-orange {
  background-color: orange !important;
  color: #fff !important;
}

.cal-month-view .bg-light-orange-80,
.cal-week-view .cal-day-columns .bg-light-orange-80,
.cal-day-view .bg-light-orange-80 {
  background-color: rgba(255, 166, 0, 0.7) !important;
  // color: #fff !important;
}
.cal-month-view .bg-light-orange-50,
.cal-week-view .cal-day-columns .bg-light-orange-50,
.cal-day-view .bg-light-orange-50 {
  background-color: rgba(255, 166, 0, 0.4) !important;
  // color: #fff !important;
}
.cal-month-view .bg-light-orange-25,
.cal-week-view .cal-day-columns .bg-light-orange-25,
.cal-day-view .bg-light-orange-25 {
  background-color: rgba(255, 166, 0, 0.2) !important;
  // color: #fff !important;
}

.ant-card-bordered {
  border: 1px solid #e7e7e7;
  box-shadow: 0px 2px 15px 2px rgba(155,155,155,0.2);
  -webkit-box-shadow: 0px 2px 15px 2px rgba(155,155,155,0.2);
  -moz-box-shadow: 0px 2px 15px 2px rgba(155,155,155,0.2);
}
.ant-card-head {
  border-bottom: 1px solid #e7e7e7;
}
.halt-color{
  background: rgb(255 0 0 / 29%);
}
.halt-card,
.halt-card .ant-card-head {
  background: #ff00002b;
}

.ant-modal {
  top: 10px !important;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  padding: 0.75rem 0.5rem 0.75rem 0.5rem;
}

.ant-card-body {
  padding: 1rem;
}

.dashboard-container {
  min-height: 300px;
  overflow-y: auto; /* Ensure that scrollbars are shown if content overflows */
}

@media (min-height: 500px) {
  .dashboard-container {
    overflow-y: auto; /* Enable scrolling for taller content */
  }
}

@media (max-height: 499px) {
  .dashboard-container {
    overflow-y: hidden; /* Hide scrollbars for shorter content */
  }
}

.error-text {
  text-decoration: none !important;
  color: red;
}